import React, { Fragment, useState } from 'react';

export const Contactus = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [eMail, setEMail] = useState('');
  const [subject, setSubject] = useState('');
  const [query, setQuery] = useState('');
  const [validEmail, setNotValidEmail] = useState('');

  const changeFirstName = (e) =>{
    setFirstName(e.target.value);
  };
  const changeLastName = (e) =>{
    setLastName(e.target.value);
  };
  const changeEMail = (e) =>{
    setEMail(e.target.value);
    if(eMail !== ''){
      setNotValidEmail('');
    }
  };
  const changeSubject = (e) =>{
    setSubject(e.target.value);
  };
  const changeQuery = (e) =>{
    setQuery(e.target.value);
  };
  const validateForm = () => {
    let isValid = true;
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if(firstName === ''){
      isValid = false;
      alert("Please enter first name!");
    }
    else if(lastName === ''){
      isValid = false;
      alert("Please enter last name!");
    }
    else if(eMail === ''){
      isValid = false;
      alert("Please enter E-Mail!");
    }
    else if (eMail !== '' && !pattern.test(eMail)) {
      setNotValidEmail("Please enter valid E-Mail.");
      isValid = false;
    }
    else if(subject === ''){
      isValid = false;
      alert("Please enter subject!");
    }
    else if(query === '' || query.trim().length < 10 ){
      isValid = false;
      alert("Please describe your query in minimum 5 words!");
    }
    return isValid;
  }
  const formSubmitHandler = (e) =>{
    e.preventDefault();
    if(validateForm()){
      const data = {
        Firstname: firstName,
        Lastname: lastName,
        Email: eMail,
        Subject: subject,
        Query: query
      };
      // calling post method.
      console.log(data);
      alert("Form submitted!");
    }
  };
  return (
    <Fragment>
      <section className="py-5 my-5 pt-md-11 bg-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="section-title my-5 py-3">
              <h2>Contact</h2>
              {/*<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit
                sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias
                ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>*/}
            </div>
          </div>
        </div>
      </section>
      <section className="my-5 py-5">
        <div className="container my-5">
          <div className="row justify-content-between">
            <div className="col-lg-5 col-md-6 mb-0 mb-md-0">
              <h4 className="font-weight-bold">Call or email</h4>
              {/*<p className="text-muted mb-4">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id quam
                sapiente molestiae.</p>*/}
              <p><span className="font-weight-bold mr-2">Email:</span><a href="/contactus">admin@ngsgp.com</a></p>
              <p><span className="font-weight-bold mr-2">Phone:</span><a href="/contactus">040 42 622 108</a></p>
            </div>
            <div className="col-lg-6 col-md-6 mb-4 mb-md-0">
              <form onSubmit={formSubmitHandler}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="md-form md-outline mb-0">
                      <input type="text" id="form-first-name" className="form-control"
                        placeholder="First Name"
                        value={firstName}
                        onChange={changeFirstName} required/>
                      <label htmlFor="form-first-name">First name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="md-form md-outline mb-0">
                      <input type="text" id="form-last-name" className="form-control"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={changeLastName} required/>
                      <label htmlFor="form-last-name">Last name</label>
                    </div>
                  </div>
                </div>
                <div className="md-form md-outline mt-3">
                  <input type="email" id="form-email" className="form-control"
                  placeholder="E-mail"
                  value={eMail}
                  onChange={changeEMail} required/>
                  <label htmlFor="form-email">E-mail</label>
                  <div className="text-danger">{validEmail}</div>
                </div>
                <div className="md-form md-outline mt-3">
                  <input type="text" id="form-subject" className="form-control"
                  placeholder="Subject"
                  value={subject}
                  onChange={changeSubject} required/>
                  <label htmlFor="form-subject">Subject</label>
                </div>
                <div className="md-form md-outline my-3">
                  <textarea id="form-message" className="md-textarea form-control" rows="3"
                  placeholder="Write your query!!"
                  value={query}
                  onChange={changeQuery} required/>
                  <label htmlFor="form-message">How we can help?</label>
                </div>
                <button type="submit" className="btn btn-info btn-sm ml-0" onClick={formSubmitHandler}>Submit<i className="far fa-paper-plane ml-2"></i></button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default Contactus;