import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class FooterSection extends Component {
    static displayName = FooterSection.name;

    render() {
        return (
            <footer>
                <div className="bg-light-skyblue py-5">
                    <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <h3>NGSGP</h3>
                            <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies
                                Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies
                                Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <h4>Useful Links</h4>
                            <ul className="list-unstyled">
                                <li>
                                    <Link to="Home">Home</Link>
                                </li>
                                <li>
                                    <Link to="about">About</Link>
                                </li>
                                <li>
                                    <Link to="services">Services</Link>
                                </li>
                                <li>
                                    <Link to="careers">Careers</Link>
                                </li>
                                <li>
                                    <Link to="contactus">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <h4>Our Services</h4>
                            <ul className="list-unstyled">
                                <li>
                                    <Link to="services">Web Development</Link>
                                </li>
                                <li>
                                    <Link to="services">Software Development</Link>
                                </li>
                                <li>
                                    <Link to="services">Mobile App Development</Link>
                                </li>
                                <li>
                                    <Link to="services">UI &ammp; UX Design</Link>
                                </li>
                                <li>
                                    <Link to="services">Digital Marketing</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-3">
                        <h4>Address</h4>
                        <p>
                            2-126<br />
                            Ponugupadu<br />
                            Guntur, Andhrapradesh <br />
                            India - 522549<br /><br />
                            <strong>Phone:</strong> +9140 42 622 108<br />
                            <strong>Email:</strong> admin@ngsgp.com<br />
                        </p>
                        <div className="social-links mt-3">
                            <Link to="#" className="mx-2 text-primary"><i className="fab fa-facebook-f"></i></Link>
                            <Link to="#" className="mx-2 text-secondary"><i className="fab fa-instagram"></i></Link>
                            <Link to="#" className="mx-2 text-danger"><i className="fab fa-google"></i></Link>
                            <Link to="#" className="mx-2 text-info"><i className="fab fa-linkedin-in"></i></Link>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="container py-4 d-flex justify-content-between">
                    <div className="copyright">
                        &copy; Copyright <strong><span>NGSGP</span></strong>. All Rights Reserved
                    </div>
                    {/* <div>
                        Designed by <Link to="#">Rohit Jella</a>
                    </div> */}
                </div>
                <button type="button" className="btn btn-danger btn-floating btn-lg" id="btn-back-to-top">
                    <i className="fas fa-arrow-up"></i>
                </button>
                </footer>
        );
    }
}
