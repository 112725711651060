import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';

export const Services = () => {
  return (
    <Fragment>
        <section className="bg-light-skyblue py-5 mt-5">
            <div className="container">
                <div className="section-title">
                    <h2>Services</h2>
                    <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
                        consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
                        fugiat
                        sit
                        in iste officiis commodi quidem hic quas.</p>
                </div>
                <div className="row text-center justify-content-center">
                    <div className="col-md-4 my-4">
                        <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                            <i className="fas fa-code fa-5x bg-info text-white p-5 rounded-circle"></i>
                            <Link to="#!">
                                <div className="mask service-color"></div>
                            </Link>
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">Web Development</h5>
                            <p className="card-text">
                                Some quick example text to build on the card title and make up the bulk of the
                                card's content.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 my-4">
                        <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                            <i className="fas fa-desktop fa-5x bg-info text-white p-5 rounded-circle"></i>
                            <Link to="#!">
                                <div className="mask service-color"></div>
                            </Link>
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">Software Development</h5>
                            <p className="card-text">
                                Some quick example text to build on the card title and make up the bulk of the
                                card's content.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 my-4">
                        <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                            <i className="fab fa-app-store fa-5x bg-info text-white p-5 rounded-circle"></i>
                            <Link to="#!">
                                <div className="mask service-color"></div>
                            </Link>
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">Mobile App Development</h5>
                            <p className="card-text">
                                Some quick example text to build on the card title and make up the bulk of the
                                card's content.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 my-4">
                        <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                            <i className="fas fa-pencil-ruler fa-5x bg-info text-white p-5 rounded-circle"></i>
                            <Link to="#!">
                                <div className="mask service-color"></div>
                            </Link>
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">UI &amp; UX Design</h5>
                            <p className="card-text">
                                Some quick example text to build on the card title and make up the bulk of the
                                card's content.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 my-4">
                        <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                            <i className="fas fa-chart-line fa-5x bg-info text-white p-5 rounded-circle"></i>
                            <Link to="#!">
                                <div className="mask service-color"></div>
                            </Link>
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">Digital Marketing</h5>
                            <p className="card-text">
                                Some quick example text to build on the card title and make up the bulk of the
                                card's content.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="py-5 bg-light-skyblue">
            <div className="container py-5">
                <div className="section-title">
                    <h2>Our Clients</h2>
                    <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
                        consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
                        fugiat sit in iste officiis commodi quidem hic quas.</p>
                </div>
                <div className="d-none d-lg-block">
                    <div id="carouselExampleSlidesOnly" className="carousel slide" data-mdb-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row">
                                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/5.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/7.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/6.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/9.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/11.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/10.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/12.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/13.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/1a.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/2a.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/3a.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/4a.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-none d-md-block d-lg-none">
                    <div id="carouselExampleSlidesOnly" className="carousel slide" data-mdb-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row">
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/5.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/7.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/6.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/11.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/10.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/12.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/1a.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/2a.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/3a.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/13.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/9.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/4a.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-block d-md-none">
                    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel" data-interval="1800">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row">
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/5.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/7.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/11.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/10.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/1a.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/2a.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/6.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/9.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/12.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/13.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/3a.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <img src="https://mdbootstrap.com/img/Photos/Template/4a.png" className="img-fluid px-4"
                                            alt="Logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
  )
}

export default Services;