import React, { Component, Fragment } from 'react';
import { FooterSection } from './FooterSection';
import { HeaderSection } from './HeaderSection';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <Fragment>
        <HeaderSection/>
        {this.props.children}
        <FooterSection/>
      </Fragment>
    );
  }
}

export default Layout;