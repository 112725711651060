import React, { Fragment} from 'react';
import { Link } from 'react-router-dom';

export const Careers = () => {
  return (
    <Fragment>
      <section className="py-5 mt-5 pt-md-11 bg-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="section-title my-5 py-3">
              <h2>Careers</h2>
              <p>Currently, we do not have any openings. Please keep visiting us for openings in future.</p>
            </div>
          </div>
        </div> 
      </section>
        {/*<section className="py-5 bg-light-skyblue">
        <div className="container">
          <div className="row justify-content-between container align-items-center">
            <div className="col-sm-6 col-md-4 col-lg-4 my-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Special title treatment</h5>
                  <p className="card-text">
                    With supporting text below as a natural lead-in to additional content.
                  </p>
                  <Link to="/careers" className="">Know More</Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 my-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Special title treatment</h5>
                  <p className="card-text">
                      With supporting text below as a natural lead-in to additional content.
                  </p>
                  <Link to="/careers" className=""><i>Know More</i></Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 my-3">
              <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Special title treatment</h5>
                    <p className="card-text">
                        With supporting text below as a natural lead-in to additional content.
                    </p>
                    <Link to="/careers" className="">Know More</Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 my-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Special title treatment</h5>
                  <p className="card-text">
                    With supporting text below as a natural lead-in to additional content.
                  </p>
                  <Link to="/careers" className="">Know More</Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 my-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Special title treatment</h5>
                  <p className="card-text">
                    With supporting text below as a natural lead-in to additional content.
                  </p>
                  <Link to="/careers" className="">Know More</Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 my-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Special title treatment</h5>
                  <p className="card-text">
                      With supporting text below as a natural lead-in to additional content.
                  </p>
                  <Link to="/careers" className="">Know More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="container">
          <div className="section-title my-5 py-3">
            <h2>Frequently Asked Questions</h2>
            <p className="lead text-muted mx-auto mt-4 pt-2 mb-5 text-center">Got a question? We've got answers. If you
              have some other questions, see our support center.</p>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-4">
              <h5 className="font-weight-normal mb-3">Is this a secure site for purchases?</h5>
              <p className="text-muted">Absolutely! We work with top payment companies which guarantees your
                safety and security.
                All billing information is stored on our payment processing partner which has the most
                stringent level of
                certification available in the payments industry.</p>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <h5 className="font-weight-normal mb-3">Can I cancel my subscription?</h5>
              <p className="text-muted">You can cancel your subscription anytime in your account. Once the
                subscription is
                cancelled, you will not be charged next month. You will continue to have access to your
                account until your
                current subscription expires.</p>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <h5 className="font-weight-normal mb-3">How long are your contracts?</h5>
              <p className="text-muted">Currently, we only offer monthly subscription. You can upgrade or
                cancel your monthly
                account at any time with no further obligation.</p>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <h5 className="font-weight-normal mb-3">Can I update my card details?</h5>
              <p className="text-muted">Yes. Go to the billing section of your dashboard and update your
                payment information.</p>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <h5 className="font-weight-normal mb-3">Can I request refund?</h5>
              <p className="text-muted">Unfortunately, not. We do not issue full or partial refunds for any
                reason.</p>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <h5 className="font-weight-normal mb-3">Can I try your service for free?</h5>
              <p className="text-muted">Of course! We’re happy to offer a free plan to anyone who wants to try
                our service.</p>
            </div>
          </div>
        </div>
          </section>
          */}
    </Fragment>
  )
}

export default Careers;