import React, { Fragment, Component } from 'react';
import {Link} from 'react-router-dom';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <Fragment>
                {/* Hero Section */}
                <section className="py-5 my-5 pt-md-11 bg-light">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-5 col-lg-6 order-md-2">

                                {/* Image */}
                                <img src="images/hero-img.jpg" className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0" alt="..." />

                            </div>
                            <div className="col-12 col-md-7 col-lg-6 order-md-1">

                                {/* Heading */}
                                <h1 className="display-4 text-center text-md-start">
                                    Next Gen Solution for <span className="text-primary">Global Partners</span><br />
                                </h1>

                                {/* Text */}
                                <p className="lead text-center text-md-start text-muted mb-6 mb-lg-8">
                                    Infuence, Impact, Opportunity
                                </p>

                                {/* Buttons */}
                                <div className="text-center text-md-start">
                                    <Link to="/about" className="btn btn-info mx-1">
                                        About <i className="fa fa-arrow-right ms-3"></i>
                                    </Link>
                                    <Link to="/contactus" className="btn btn-secondary mx-1">
                                        Contact us
                                    </Link>
                                </div>

                            </div>
                        </div> {/* / .row */}
                    </div> {/* / .container */}
                </section>
                {/* End Section */}
                {/*  About Section  */}
                <section className="py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5" data-aos="zoom-in">
                                <img src="images/Microsoft365.png" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-contents-cente" data-aos="fade-left">
                                <div className="content pt-4 pt-lg-0 text-center">
                                    <div className="section-title">
                                        <h2>How do we work</h2>
                                    </div>
                                    <p>We use Microsoft365 for day to day business</p>
                                    <p>
                                        We follow world class business working style with Microsoft365 for Collaboration, Remote work.
                                       It is secure and productive.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {/* End About Section */}
                {/*  Services Section  */}
                <section className="bg-light-skyblue py-5 mt-5">
                    <div className="container">

                        <div className="section-title">
                            <h2>Services</h2>
                            {/*  <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
                                consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
                                fugiat
                                sit
                                in iste officiis commodi quidem hic quas.</p>*/}
                        </div>

                        <div className="row text-center justify-content-center">
                            <div className="col-md-4 my-4">
                                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                    <i className="fas fa-code fa-5x bg-info text-white p-5 rounded-circle"></i>
                                    <Link to="#!">
                                        <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                                    </Link>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">Web Development</h5>
                                    {/*     <p className="card-text">
                                        Some quick example text to build on the card title and make up the bulk of the
                                        card's content.
                                    </p> */}
                                </div>
                            </div>
                            <div className="col-md-4 my-4">
                                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                    <i className="fas fa-desktop fa-5x bg-info text-white p-5 rounded-circle"></i>
                                    <Link to="#!">
                                        <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                                    </Link>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">Software Development</h5>
                                    {/* <p className="card-text">
                                        Some quick example text to build on the card title and make up the bulk of the
                                        card's content.
                                    </p> */}
                                </div>
                            </div>
                            <div className="col-md-4 my-4">
                                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                    <i className="fab fa-app-store fa-5x bg-info text-white p-5 rounded-circle"></i>
                                    <Link to="#!">
                                        <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                                    </Link>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">Mobile App Development</h5>
                                    {/* <p className="card-text">
                                        Some quick example text to build on the card title and make up the bulk of the
                                        card's content.
                                    </p>  */}
                                </div>
                            </div>
                            <div className="col-md-4 my-4">
                                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                    <i className="fas fa-pencil-ruler fa-5x bg-info text-white p-5 rounded-circle"></i>
                                    <Link to="#!">
                                        <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                                    </Link>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">UI & UX Design</h5>
                                    {/*  <p className="card-text">
                                        Some quick example text to build on the card title and make up the bulk of the
                                        card's content.
                                    </p> */}
                                </div>
                            </div>
                            <div className="col-md-4 my-4">
                                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                    <i className="fas fa-chart-line fa-5x bg-info text-white p-5 rounded-circle"></i>
                                    <Link to="#!">
                                        <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                                    </Link>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">Digital Marketing</h5>
                                    {/* <p className="card-text">
                                        Some quick example text to build on the card title and make up the bulk of the
                                        card's content.
                                    </p>  */}
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {/* End Services Section */}

                {/*  Team Section  */}
                {/*
                <section className="py-5 bg-light">
                    <div className="container">

                        <div className="section-title">
                            <h2>Team</h2>
                            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
                                consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
                                fugiat
                                sit
                                in iste officiis commodi quidem hic quas.</p>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                        <img src="images/team-1.jpg" className="img-fluid" />
                                        <Link>
                                            <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                                        </a>
                                    </div>
                                    <div className="card-body text-center">
                                        <p className="card-title h3">Walter White</p>
                                        <p className="card-text">
                                            Chief Executive Officer
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                        <img src="images/team-2.jpg" className="img-fluid" />
                                        <Link>
                                            <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                                        </a>
                                    </div>
                                    <div className="card-body text-center">
                                        <p className="card-title h3">Sarah Jhonson</p>
                                        <p className="card-text">
                                            Product Manager
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                        <img src="images/team-3.jpg" className="img-fluid" />
                                        <Link>
                                            <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                                        </a>
                                    </div>
                                    <div className="card-body text-center">
                                        <p className="card-title h3">William Anderson</p>
                                        <p className="card-text">
                                            CTO
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                        <img src="images/team-4.jpg" className="img-fluid" />
                                        <Link>
                                            <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                                        </a>
                                    </div>
                                    <div className="card-body text-center">
                                        <p className="card-title h3">Amanda Jepson</p>
                                        <p className="card-text">
                                            Accountant
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                */}
                {/* End Team Section */}
                {/*  client Section  */}
                <section className="py-5">
                    <div className="container py-5">

                        <div className="section-title">
                            <h2>Our Clients</h2>
                            {/*<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
                                consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
                                fugiat sit in iste officiis commodi quidem hic quas.</p>*/}
                        </div>
                        <div className="d-none d-lg-block">
                            <div id="carouselExampleSlidesOnly" className="carousel slide" data-mdb-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        {/*Grid row*/}
                                        <div className="row text-center">

                                            {/*First column*/}
                                            <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                                <img src="images/PracMed_logo.png" className="img-fluid px-4"
                                                    alt="Logo" />
                                            </div>
                                            {/*/First column*/}

                                            {/*Second column*/}
                                            <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                                <img src="images/PRTU.png" className="img-fluid px-4"
                                                    alt="Logo" />
                                            </div>
                                            {/*/Second column*/}

                                            {/*Third column*/}
                                            <div className="col-lg-3 d-flex align-items-center justify-content-center">
                                                <img src="images/Melo.png" className="img-fluid px-4"
                                                    alt="Logo" />
                                            </div>
                                            {/*/Third column*/}

                                        </div>
                                        {/*/Grid row*/}
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                </section>
                {/* End client Section */}
                {/* testimonial Section */}
                {/* <section className="py-5 bg-light-skyblue">
                    <div className="container py-5">
                        <div className="section-title">
                            <h2>Testimonials</h2>
                            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
                                consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
                                fugiat sit in iste officiis commodi quidem hic quas.</p>
                        </div>
                        <div id="carouselExampleSlidesOnly" className="carousel slide text-center" data-mdb-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <p className="lead font-italic">"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
                                        error amet numquam iure
                                        provident voluptate esse quasi, voluptas nostrum quisquam!"</p>
                                    <div className="view card-img-64 mx-auto mt-5 mb-4">
                                        <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(2).jpg"
                                            className="rounded-circle img-fluid" width="150" alt="smaple" />
                                    </div>
                                    <p className="text-muted">- Anna Morian</p>
                                </div>
                                <div className="carousel-item">
                                    <p className="lead font-italic">"Neque cupiditate assumenda in maiores repudiandae mollitia adipisci
                                        maiores repudiandae
                                        mollitia consectetur adipisicing architecto elit sed adipiscing elit."</p>
                                    <div className="view card-img-64 mx-auto mt-5 mb-4">
                                        <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg"
                                            className="rounded-circle img-fluid" width="150" alt="smaple" />
                                    </div>
                                    <p className="text-muted">- Teresa May</p>
                                </div>
                                <div className="carousel-item">
                                    <p className="lead font-italic">"Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat
                                        nulla pariatur est laborum neque cupiditate assumenda in maiores."</p>
                                    <div className="view card-img-64 mx-auto mt-5 mb-4">
                                        <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(10).jpg"
                                            className="rounded-circle img-fluid" width="150" alt="smaple" />
                                    </div>
                                    <p className="text-muted">- Kate Allise</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
                {/* end testimonial Section */}


            </Fragment>
        );
    }
}
export default Home;