import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Careers from './components/Careers';
import Contactus from './components/Contactus';

/*import './custom.css'*/

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/Home' component={Home} />
        <Route path='/about' component={About} />
        <Route path='/services' component={Services} />
        <Route path='/careers' component={Careers} />
        <Route path='/contactus' component={Contactus} />
        <Route path='*'><Redirect to='/' /></Route>
      </Layout>
    );
  }
}
