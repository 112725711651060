import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class HeaderSection extends Component {
    static displayName = HeaderSection.name;

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src="images/logo.png" height="40" alt="" loading="lazy" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-mdb-toggle="collapse"
                        data-mdb-target="#navbarRightAlignExample" aria-controls="navbarRightAlignExample" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <i className="fas fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarRightAlignExample">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink className="nav-link active" aria-current="page" title="Home" tag={Link} to="/home">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" title="About Us" tag={Link} to="about">About Us</NavLink>
                                
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" title="Services" tag={Link} to="/services">Services</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" title="Careers" tag={Link} to="/careers">Careers</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" title="Contact Us" tag={Link} to="/contactus">Contact Us</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}
