import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';

export const About = () => {
  return (
    <Fragment>
      <section className="py-5 my-5 pt-md-11 bg-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="section-title my-5 py-3">
                <h2>About</h2>
                <p>We are start up </p>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="container">
          <div className="row justify-content-between container align-items-center">
            <div className="col-md-5">
              <div className="view">
                <img src="images/why-us.svg" className="img-fluid" alt="smaple"/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="section-title">
                <h2>Why us</h2>
                <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem.
                    Sit sint consectetur velit. Quisquam quos quisquam cupiditate.
                </p>
              </div>
              <h4 className="title">Lorem Ipsum</h4>
              <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias
                  excepturi sint occaecati cupiditate non provident</p>
              <h4 className="title">Nemo Enim</h4>
              <p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                  praesentium voluptatum deleniti atque</p>/
              <h4 className="title">Dine Pad</h4>
              <p className="description">Explicabo est voluptatum asperiores consequatur magnam. Et veritatis
                  odit. Sunt aut deserunt minus aut eligendi omnis</p>
            </div>
          </div>
        </div>
      </section>
      <section className="px-md-5 mx-md-5 py-5 text-center bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              <i className="fas fa-rocket fa-3x blue-text"></i>
              <h3 className="font-weight-bold my-3">Mission</h3>
              <p className="text-muted mx-md-5">Infuence, Impact, Opportunity</p>
            </div>
            <div className="col-md-6 mb-4 mb-md-0">
              <i className="fas fa-eye fa-3x green-text"></i>
              <h3 className="font-weight-bold my-3">Vision</h3>
              <p className="text-muted mx-md-5"> Providing Quality, Mission driven and Customer centric solutions for Global Partners</p>
            </div>
          </div>
        </div>
      </section>
          {/*<section className="py-5 bg-light">
        <div className="container">
          <div className="section-title">
            <h2>Team</h2>
            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
              consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
              fugiat
              sit
              in iste officiis commodi quidem hic quas.</p>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="card">
                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="images/team-1.jpg" className="img-fluid" alt="img"/>
                  <Link to="/about">
                    <div className="mask service-color"></div>
                  </Link>
                </div>
                <div className="card-body text-center">
                  <p className="card-title h3">Walter White</p>
                  <p className="card-text">
                    Chief Executive Officer
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="images/team-2.jpg" className="img-fluid" alt="img"/>
                  <Link to="/about">
                    <div className="mask service-color"></div>
                  </Link>
                </div>
                <div className="card-body text-center">
                  <p className="card-title h3">Sarah Jhonson</p>
                  <p className="card-text">
                    Product Manager
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="images/team-3.jpg" className="img-fluid" alt="img"/>
                  <Link to="/about">
                    <div className="mask service-color"></div>
                  </Link>
                </div>
                <div className="card-body text-center">
                  <p className="card-title h3">William Anderson</p>
                  <p className="card-text">
                    CTO
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                  <img src="images/team-4.jpg" className="img-fluid" alt="img"/>
                  <Link to="/about">
                    <div className="mask service-color" ></div>
                  </Link>
                </div>
                <div className="card-body text-center">
                  <p className="card-title h3">Amanda Jepson</p>
                  <p className="card-text">
                    Accountant
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </Fragment>
  )
}

export default About; 